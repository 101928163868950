(function($) {

	$(document).ready(function(){

    var clickMenuDelay      = 400,
    clickMenuDelayTimer = null;
    
    // Model script burger menu //
    $('.burger-click-region').on('click', function () {

        if (clickMenuDelayTimer === null) {

            var $burger = $(this);
            $burger.toggleClass('active');
            $burger.parent().toggleClass('is-open');

            if (!$burger.hasClass('active')) {
                $burger.addClass('closing');
            }

            clickMenuDelayTimer = setTimeout(function () {
                $burger.removeClass('closing');
                clearTimeout(clickMenuDelayTimer);
                clickMenuDelayTimer = null;
            }, clickMenuDelay);
        }
    });

    $('.menuLink').mouseover(function(){
      if ($(this).hasClass('active')) {
          $(this).addClass('closing');
      } else {
        $(this).addClass('active');
      }
    });

    $('.menuLink').mouseout(function(){
      if ($(this).hasClass('active')) {
          $(this).removeClass('active');
      }
    });
    
//     $('.menuLink>a').on('click', function (e) {
//         e.preventDefault();
//         e.stopImmediatePropagation();
//         e.stopPropagation();
//         if(clickMenuDelayTimer === null) {
            
// //            if($(this).hasClass('active')) {
// //                $('.firstLevelNav.active').removeClass("active");
// //            }
            
//             if($(".menuLink").not($(this).parent('li')).hasClass('active')) {
//                 $('.menuLink.active').removeClass("active");
//             }
            
//             var $burger = $(this).parent('li');

//             $burger.toggleClass('active');

//             if(!$burger.hasClass('active')) {
//                 $burger.addClass('closing');
//             }

//             clickMenuDelayTimer = setTimeout(function () {
//                 $burger.removeClass('closing');
//                 clearTimeout(clickMenuDelayTimer);
//                 clickMenuDelayTimer = null;
//             }, clickMenuDelay);
//         }
//     });
    
    $('.search i').on('click', function () {

        if (clickMenuDelayTimer === null) {

            var $burger = $(".search");
            $burger.toggleClass('active');
            $burger.parent().toggleClass('is-open');

            if (!$burger.hasClass('active')) {
                $burger.addClass('closing');
            }

            clickMenuDelayTimer = setTimeout(function () {
                $burger.removeClass('closing');
                clearTimeout(clickMenuDelayTimer);
                clickMenuDelayTimer = null;
            }, clickMenuDelay);
        }
        
        // $(this).toggleClass('icofont-search');
        // $(this).toggleClass('icofont-close-line');
    });

    $(".menuLink a span").each(function (i) {
        var words = $(this).text();
        var arrofwords = words.split(" ");
        
        if(arrofwords.length > 1) {
            var middle = arrofwords.length / 2;
            arrofwords.splice(middle, 0, "<br/>");
            var output = arrofwords.join(" ");
            $(this).empty().append(output);
        }

    });

    // ****************** Navigation progressive ***************** //
    if ($(window).width() > 992) {
      var $nav = $('.menu_compacted .menu-principal');
      var $btn = $('.menu_compacted button');
      var $vlinks = $('.menu_compacted .menu-principal #menu-menu-principal');
      var $hlinks = $('.menu_compacted .hidden-links');

      var breaks = [];

      function updateNav() {

        var wtotal = 0;

        $("#menu-menu-principal>li").each(function(index){
          wtotal += $( this ).width();
        });

        var availableSpace = $btn.hasClass('hidden') ? $nav.width() : $nav.width() - $btn.width() - 30;

        // The visible list is overflowing the nav
        if(wtotal > availableSpace) {

          // Record the width of the list
          breaks.push(wtotal);

          // Move item to the hidden list
          $vlinks.children().prev().last().prependTo($hlinks);

          // Show the dropdown btn
          if($btn.hasClass('hiddens')) {
            $btn.removeClass('hiddens');
          }

        // The visible list is not overflowing
        } else {

          // There is space for another item in the nav
          if(availableSpace > breaks[breaks.length-1]) {

            // Move the item to the visible list
            $vlinks.children().last().before($hlinks.children().first());
            breaks.pop();
          }

          // Hide the dropdown btn if hidden list is empty
          if(breaks.length < 1) {
            $btn.addClass('hiddens');
            $hlinks.addClass('hiddens');
          }
        }

        // Keep counter updated
        $btn.attr("count", breaks.length);

        // Recur if the visible list is still overflowing the nav
        if(wtotal > availableSpace) {
          updateNav();
        }

      }

      // Window listeners

      $(window).resize(function() {
          updateNav();
          updateHover();
      });

      $btn.on('click', function() {
        $hlinks.toggleClass('hiddens');
      });

      updateNav();
      updateHover();
    };


    function updateHover(){
      $(".hidden-links>li").hover(function(){
        var submenu = $(this).find('.sub-menu').height()+14;
        $(this).toggleClass('open-submenu');
        if($(this).hasClass('open-submenu')){
          $(this).css('padding-bottom', submenu);
          $(this).find('.sub-menu').css('margin-top', '-25px');
        } else {
          $(this).css('padding-bottom', '14px');
          $(this).find('.sub-menu').css('margin-top', '0px');
        }
      });
    }
    
    // Clic ou echap pour enlever le menu actif
    $(document).click(function (event) {
        if (event.target.class !== 'firstLevelMenu' || event.target.class !== 'secondLevelMenu' || event.target.class !== 'thirdLevelMenu') {
            $('.menuLink').removeClass('active');
        }
    });
    
    $(document).on('keyup', function(evt) {
        if (evt.keyCode == 27) {
            $('.menuLink').removeClass('active');
        }
    });
	  
    $('.headerSlider').slick({
        arrows: false,
        dots: true,
        fade: true,
    });
    
    $('.slick-prev').click(function(event){
        event.preventDefault();
        $('.headerSlider').slick("slickPrev");
    });
    
    $('.slick-next').click(function(event){
        event.preventDefault();
        $('.headerSlider').slick("slickNext");
    });

    $('.extend_toolbar').on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
        $(this).hide();
        $('#aside_right_toolbar .right_aside').show();
        $('.close_toolbar').css('display', 'block');
    });

    $('.close_toolbar').on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
        $(this).hide();
        $('#aside_right_toolbar .right_aside').hide();
        $('.extend_toolbar').css('display', 'block');
    });

	  $("#datepicker-from").datepicker({
      dateFormat: 'dd/mm/yy',
    });
    $("#datepicker-to").datepicker({
      dateFormat: 'dd/mm/yy',
    });
      $.datepicker.regional['fr'] = {
          clearText: 'Effacer',
          clearStatus: '',
          closeText: 'Fermer',
          closeStatus: 'Fermer sans modifier',
          prevText: '<Préc',
          prevStatus: 'Voir le mois précédent',
          nextText: 'Suiv>',
          nextStatus: 'Voir le mois suivant',
          currentText: 'Courant',
          currentStatus: 'Voir le mois courant',
          monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
                  'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
          ],
          monthNamesShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Jun',
                  'Jul', 'Aoû', 'Sep', 'Oct', 'Nov', 'Déc'
          ],
          monthStatus: 'Voir un autre mois',
          yearStatus: 'Voir un autre année',
          weekHeader: 'Sm',
          weekStatus: '',
          dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
          dayNamesShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
          dayNamesMin: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
          dayStatus: 'Utiliser DD comme premier jour de la semaine',
          dateStatus: 'Choisir le DD, MM d',
          dateFormat: 'dd/mm/yy',
          firstDay: 0,
          initStatus: 'Choisir la date',
          isRTL: false
      };
      $.datepicker.setDefaults($.datepicker.regional['fr']);

      var date = $.datepicker.formatDate('dd/mm/yy', new Date());
      $("#datepicker-from").attr("placeholder",date);
      $("#datepicker-to").attr("placeholder",date);

      if($('.customSelect').length) {
        $('.customSelect').select2({
            width: 'style',
        });
      }

      $('#formAgenda .reset').click(function (e) {
        event.preventDefault();
        $("#formAgenda .customSelect option[value='0']").attr('selected',true);
        $("#formAgenda #datepicker-from").val("");
        $("#formAgenda #datepicker-to").val("");
        $( "#formAgenda" ).submit();
      });

      $('#formActualite .reset').click(function (e) {
        event.preventDefault();
        $("#formActualite .customSelect option[value='0']").attr('selected',true);
        $( "#formActualite" ).submit();
      });

      $('#formCarteInteractive .reinit-filtre').click(function (e) {
        event.preventDefault();
        $('.customCheckbox').children('input').removeAttr('checked');
        $( "#formCarteInteractive" ).submit();
      });

      $("#wp-realtime-sitemap-annuaires").click(function (event) {
        event.preventDefault();
        event.stopPropagation();
        window.location.href = '/carte_interactive';
      });

	});

  $('.contenuBloc h2').each(function(index){
    var titre_h2 = $(this).html();
    $(this).empty();

    $(this).append('<span>'+titre_h2+'</span>');
  });

})(jQuery);